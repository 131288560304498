html{
  background-color: #333333;
}

*{
  margin: 0;
  padding: 0;
}

ul{
  margin-left: 1rem;
}

.code-inline{
  font-family: monospace;
  border-radius: 0.5rem;
  padding: 0.1rem 0.4rem;
  margin: 0rem 0.3rem;
  font-size: 1rem;
  background-color: #111;
  color: #D6AD60;
}

.code{
  font-family: monospace;
  padding: 0.6rem 0.4rem;
  margin: 1rem 0rem;
  font-size: 1rem;
  line-height: 1.4rem;
  background-color: #111;
  color: #D6AD60;
}

::selection{
  background-color: #D6AD60;
  color: #222222;
}

.white{
  color: #FFFFFF;
}

.dark-white{
  color: #F4EBD0
}

.primary-light{
  color: #D6AD60;
}

.primary-dark{
  color: #222222;
}

.dark1{
  color: #333333;
}

.dark2{
  color: #383838;
}

.pageBG-dark{
  background-color: #333333;
}

.primaryBG-dark{
  background-color: #222222;
}

.primaryBG-light{
  background-color: #D6AD60;
}

.black100{
  color: #121212;
}

.black75{
  color: #4D4D4D;
}

.font-style-cta2{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.24rem;
}

.font-style-cta{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.24rem;
}

.font-style-h1{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 2.7rem;
  line-height: 3.26rem;
}

.font-style-h2{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.48rem;
}

.font-style-content{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.69rem;
}

.font-style-content2{
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.43rem;
}

.bold{
  font-weight: 700;
}

.strike{
  text-decoration: line-through;
}

.italic{
  font-style: italic;
}

.chip-outlined{
    width: fit-content;
    padding: 0.14rem 0.6rem;
    margin-right: 0.8rem;
    margin-top: 0.6rem;
    color: #FFFFFF !important;
    background: #222222;
    border: 0.1rem solid #D6AD60;
    border-radius: 1.68rem;
    cursor: default;
    word-break: break-all;
  }
  
  .chip-filled{
    width: fit-content;
    padding: 0.14rem 0.4rem ;
    margin-right: 0.56rem;
    margin-top: 0.6rem;
    border-radius: 1.4rem;
    color: #222222;
    background-color: #D6AD60;
    cursor: default;
    word-break: break-all;
}

.chip-link{
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.chip-container{
    display: flex;
    flex-flow: row wrap;
}